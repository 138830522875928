<template>
  <tr>
    <td scope="row">
      <div class="vertical-imgs">
        <img alt="product main photo" class="item" :src="getProductImageSrc(line.productImageId)">
      </div>
    </td>
    <td>
      {{ line.productName }} <span v-if="line.quantityInBox > 1">({{line.quantityInBox}} шт.)</span>
      <span v-if="line.sizeName" class="size-name">{{line.sizeName}}</span>
    </td>
    <td>
      <product-quantity
        :product-id="line.productId"
        :size-id="line.sizeId"
        :quantity="line.quantity"
        :balance="line.structuralSubdivisionProductBalance"
      />
    </td>
    <td v-if="line.quantity < line.minimumCountForOrder">
      <div class="row ml-0">
        <span class="min-order">мин. заказ: {{ line.minimumCountForOrder }} шт.</span>
      </div>
    </td>
    <td>{{ line.structuralSubdivisionProductBalance }}</td>
    <td class="text-center">
      <button
        class="close-oval"
        @click="removeProductUpdateLine({productId: line.productId, sizeId: line.sizeId, quantity: line.quantity, completely: true})"
      >
        <span class="close">&times;</span>
      </button>
    </td>
  </tr>
</template>

<script>
import CartLineProductQuantity from "./CartLineProductQuantity";
import {mapActions, mapGetters} from "vuex";


export default {
  name: "CartLineDesktop",
  components: {
    'product-quantity': CartLineProductQuantity
  },
  props: {
    line: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('common', ['getProductImageSrc'])
  },
  methods: {
    ...mapActions('cart/subdivision', ['removeProductUpdateLine'])
  }
}
</script>

<style scoped>
.item {
  width: 100%;
  height: 100%;
  /*margin-top: 16px;*/
}

.table td, .table th {
  border: none;
  border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table td {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
}

.close-oval {
  width: 26px;
  height: 26px;
  background-color: #4a4a4a;
  border: none;
  border-radius: 50%;
  margin-top: 6px;
  cursor: pointer;
}

.close {
  font-size: 20px;
  font-weight: normal;
  font-family: Serif;
  color: white;
  position: relative;
  opacity: 1;
  top: -1px;
  text-align: center;
  left: -1px;
}

td {
  font-weight: 600;
  vertical-align: middle;
  font-size: 16px;
}

.vertical-imgs {
  width: 60px;
  height: 77px;
}

@media (max-width: 767px) {
  .vertical-imgs {
    margin: 50px 70px 50px 20px;
  }
}

.min-order {
  font-size: .8em;
  font-weight: bold;
  color: red;
}
</style>
