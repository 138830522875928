<template>
  <div class="row ml-0">
    <button class="minus" @click="removeProductUpdateLine({productId, sizeId, quantity})">
      <img src="@/assets/images/minus.svg" alt="Уменьшить количество товара">
    </button>
    <input type="text" min="1" name="quantityInput" class="quantity" @input="onQuantityChange" :value="quantity"/>
    <button class="plus" @click="addProductUpdateLine({productId, sizeId, quantity, balance })">
      <img src="@/assets/images/plus.svg" alt="Добавить количество товара">
    </button>
  </div>
</template>

<script>
import {mapActions} from "vuex";
const debounce = require('lodash.debounce');

export default {
  name: "CartLineProductQuantityDesktop",
  props: {
    productId: {
      type: String
    },
    sizeId: {
      type: Number
    },
    quantity: {
      type: Number
    },
    balance: {
      type: Number
    },
  },
  methods: {
    ...mapActions('cart/subdivision', ['addProductUpdateLine', 'removeProductUpdateLine', 'changeQuantity']),
    onQuantityChange(event) {
      if (!event.target.value) {
        return;
      }
      if (isNaN(event.target.value) || event.target.value <= 0 || event.target.value > this.balance) {
        event.target.value = this.quantity;
        return;
      }
      if (!/[0-9]/.test(event.target.value)) {
        event.target.value = this.quantity;
        return;
      }

      const debouncedQuantity = debounce(() => this.changeQuantity({
        productId: this.productId,
        sizeId: this.sizeId,
        newQuantity: event.target.value,
        balance: this.balance,
        currentQuantity: this.quantity
      }), 500)
      debouncedQuantity();
    },
  }
}
</script>

<style scoped>
.minus, .plus {
  width: 25px;
  height: 100%;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

@media (max-width: 767px) {
  .minus, .plus {
    float: left;
    display: inline-block;
    padding: 0;
  }
}


.quantity {
  width: 40px;
  height: 25px;
  margin-top: 0;
  text-align: center;
}

input[type=number].quantity::-webkit-inner-spin-button,
input[type=number].quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
